import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { colors, scaleSpacing, mediaquery } from "../../../../styles/theme";
import { grid } from "../../../../styles/grid";
import Testimonial from "../../Molecules/Testimonial";

const Container = styled.section`
  background: ${colors.white};
  padding: ${scaleSpacing(16)} var(--external-gutter);

  > div:last-of-type {
    margin-top: ${scaleSpacing(16)};
    position: relative;

    &:before {
      ${mediaquery.md(css`
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        top: -${scaleSpacing(8)};
        left: 0;
        background: ${colors.lightgrey2};
      `)};
    }
  }

  ${mediaquery.md(css`
    ${grid};
    padding-top: ${scaleSpacing(30)};
    padding-bottom: ${scaleSpacing(30)};

    > div {
      grid-column: 3 / span 9;
      display: flex;
      align-items: flex-end;

      &:last-of-type {
        grid-column: span 9 / -3;
        margin-top: 0;

        &:before {
          width: 1px;
          height: 100%;
          top: 0;
          left: calc(-1 * var(--col) - var(--gutter-size) / 2);
        }
      }
    }
  `)};
`;

const Testimonials = ({ testimonials }) => (
  <Container>
    {testimonials.map(
      (
        {
          picture,
          title,
          name,
          description,
          shortTestimonial,
          longTestimonial,
          logo,
        },
        index
      ) => (
        <Testimonial
          key={index}
          picture={picture}
          title={title}
          name={name}
          description={description}
          shortTestimonial={shortTestimonial}
          longTestimonial={longTestimonial}
          logo={logo}
        />
      )
    )}
  </Container>
);

Testimonials.propTypes = {
  testimonials: PropTypes.array.isRequired,
};

export default React.memo(Testimonials);
