export default ({ primary }, { t }) => {
  const {
    featured_episode,
    featured_episode_cta_label,
    featured_episode_cta_link,
  } = primary;
  if (!featured_episode) return null;

  const { data, url } = featured_episode?.document || {};
  const { headline, image, number, films } = data || {};

  return {
    label: t("curiosity_herowithcarousel_label", { count: number }),
    title: headline,
    free: t("curiosity_herowithcarousel_free", { count: 5 }),
    backgroundImage: image,
    featured_episode_cta_label,
    featured_episode_cta_link,
    ctaLabel: featured_episode_cta_label,
    ctaLink: {
      url: featured_episode_cta_link?.url || url,
    },
    cards: films,
  };
};
