import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import mappers from "./mappers";
import { useTheme } from "emotion-theming";

const SliceZone = ({ sliceZone, template, adsConfig }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const sliceZoneContent = sliceZone?.body?.map((slice, index) => {
    const { Component, mapper } = mappers[`slice_${slice.slice_type}`] || {};
    if (!Component || !mapper) return null;

    return (
      <Component
        {...mapper({ template, ...slice, adsConfig }, { theme, t })}
        pageType={sliceZone?.category}
        key={`slice-${index}`}
      />
    );
  });

  return <main className="container">{sliceZoneContent}</main>;
};

SliceZone.propTypes = {
  sliceZone: PropTypes.object,
  adsConfig: PropTypes.object,
  theme: PropTypes.string,
  template: PropTypes.string,
};

export default SliceZone;
