import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { colors, mediaquery, scaleSpacing } from "../../../../styles/theme";
import {
  NotoLarge,
  OswaldOverline1,
  OswaldOverline2,
  OswaldH1,
  OswaldH2,
} from "../../Atoms/Typography";
import CardSmall from "../../Molecules/CardSmall";
import Link from "../../Atoms/Link";
import SwiperCarousel from "../../Molecules/SwiperCarousel/SwiperCarousel";

const Container = styled.div`
  position: relative;
  .swiper-slide {
    height: auto;
  }
  ${({ desktopOnly }) =>
    desktopOnly &&
    css`
      display: none;

      ${mediaquery.md(css`
        display: block;
      `)};
    `};
`;

const ViewAllLink = styled(Link)`
  ${NotoLarge};
  color: ${({ type }) => (type === "Magazine" ? colors.black : colors.white)};
  margin: ${scaleSpacing(4)} 0 0 0;

  ${mediaquery.sm(css`
    margin: 0 0 0 ${scaleSpacing(10)};
  `)};

  ${({ type }) =>
    type === "Magazine" &&
    css`
      ${mediaquery.sm(css`
        margin: ${scaleSpacing(6)} 0 0 0;
      `)}
    `}
`;

const Title = styled.h2`
  ${OswaldOverline2};
  -webkit-text-stroke: ${({ type }) =>
    type === "Magazine" ? `1px ${colors.black}` : "none"};
  display: inline-block;
  color: ${colors.white};

  ${mediaquery.md(css`
    ${OswaldOverline1};
  `)};

  ${({ type }) =>
    type === "Magazine" &&
    css`
      ${OswaldH2};
      text-align: center;
      ${mediaquery.md(css`
        ${OswaldH1};
        font-weight: 500;
      `)}
    `}
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CardSmallCarousel = ({
  id,
  title,
  cards,
  type,
  animate,
  desktopOnly,
  authorsCtaLink,
  authorsCtaLabel,
  magazineCtaLabel,
  magazineCtaLink,
  hasLink,
  magazines,
  authors,
}) => {
  if (!cards && !authors && !magazines) {
    return null;
  }

  const renderTitle = type => {
    return (
      <Flex type={type}>
        <Title type={type}>{title}</Title>
      </Flex>
    );
  };

  const renderViewAllLink = type => (
    <Flex type={type}>
      <ViewAllLink link={authorsCtaLink || magazineCtaLink} type={type}>
        {authorsCtaLabel || magazineCtaLabel}
      </ViewAllLink>
    </Flex>
  );

  const renderCard = (cardData, animate, animatedStyles) => {
    return (
      <CardSmall
        {...cardData}
        animate={animate}
        animatedStyles={animatedStyles}
        type={type}
      />
    );
  };

  return (
    <Container desktopOnly={desktopOnly}>
      <SwiperCarousel
        id={id}
        type={type}
        cards={cards}
        authors={authors}
        magazines={magazines}
        animate={animate}
        noScrollbar
        renderTitle={renderTitle}
        renderCard={renderCard}
        renderViewAllLink={hasLink ? renderViewAllLink : () => {}}
      />
    </Container>
  );
};

CardSmallCarousel.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,

  animate: PropTypes.bool,
  type: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.object,
    })
  ),
  desktopOnly: PropTypes.bool,
  authorsCtaLabel: PropTypes.string,
  magazineCtaLabel: PropTypes.string,
  authorsCtaLink: PropTypes.object,
  magazineCtaLink: PropTypes.object,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.object,
    })
  ),
  magazines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.object,
    })
  ),
  hasLink: PropTypes.bool,
};

CardSmallCarousel.defaultProps = {
  hasLink: false,
};

export default React.memo(CardSmallCarousel);
