import { adapter } from "../../Molecules/Card";
import {
  episodesCardAdapter,
  inTheatersCardAdapter,
  podcastsCardAdapter,
} from "../../Molecules/Card/adapter";
import { linkResolver } from "../../../config/linkResolver";

export default ({ id, items, primary }, { theme, t }) => {
  const { carousel_title, carousel_type } = primary || {};

  return {
    id,
    title: carousel_title,
    type: carousel_type,
    theme: theme?.label,
    cards:
      items &&
      items.map(cardData => {
        const { data, url } = cardData?.card?.document || {};
        return {
          ...adapter({ ...data, url }, { t }),
        };
      }),
  };
};

export const cardCarouselEpisodesSliceAdapter = (
  { primary, items, id },
  { t, theme }
) => {
  const moreLink = {
    type: "category",
    uid: "curiosities",
  };

  return {
    id,
    theme: theme?.label,
    type: "dark",
    link: {
      url: linkResolver(moreLink),
    },
    linkLabel: t("curiosity_imagesCarousel_cta_more"),
    animate: true,
    showButton: true,
    title:
      primary.episodes_carousel_title?.raw ||
      t("curiosity_cardcarousel_episodestitle"),
    cards:
      items &&
      items
        .map(({ episode }) => {
          const { data, url } = episode?.document || {};
          return {
            ...episodesCardAdapter({ id, card: { ...data, url } }, { t }),
          };
        })
        .filter(card => !!card.image),
  };
};

export const cardCarouselEpisodesAdapter = ({ id, cards }, { t, theme }) => {
  const moreLink = {
    type: "category",
    uid: "curiosities",
  };

  return {
    id,
    theme: theme?.label,
    type: "dark",
    link: {
      url: linkResolver(moreLink),
    },
    linkLabel: t("curiosity_imagesCarousel_cta_more"),
    animate: true,
    showButton: true,
    title: t("curiosity_cardcarousel_episodestitle"),
    cards:
      cards &&
      cards
        .map(cardData => {
          const { data, url } = cardData?.node || {};
          return {
            ...episodesCardAdapter({ id, card: { ...data, url } }, { t }),
          };
        })
        .filter(card => !!card.image),
  };
};

export const cardCarouselPortraitAdapter = (
  { id, portraitsContent },
  { theme, t }
) => {
  const {
    portraits_carousel,
    portraits_cta_label,
    portraits_title,
    portraits_cta_link,
  } = portraitsContent || {};

  return {
    id,
    theme: theme?.label,
    type: "Portrait",
    linkLabel: portraits_cta_label,
    link: portraits_cta_link,
    animate: true,
    showButton: false,
    title: portraits_title,
    cards:
      portraits_carousel &&
      portraits_carousel.map(cardData => {
        const { data, url } = cardData?.article?.document || {};
        return {
          ...adapter({ card: { ...data, url, type: "Portrait" } }, { t }),
        };
      }),
  };
};

export const cardCarouselPodcastAdapter = (
  { id, podcastsContent },
  { theme }
) => {
  const {
    podcasts_carousel,
    podcast_cta_label,
    podcast_title,
    podcast_cta_link,
  } = podcastsContent || {};

  return {
    id,
    theme: theme?.label,
    type: "Podcast",
    linkLabel: podcast_cta_label,
    link: podcast_cta_link,
    animate: true,
    showButton: false,
    title: podcast_title,
    cards:
      podcasts_carousel &&
      podcasts_carousel.map(cardData => {
        const { data, url } = cardData?.article?.document || {};
        return {
          ...podcastsCardAdapter({ card: { ...data, url } }),
        };
      }),
  };
};

export const cardCarouselInTheatersAdapter = (
  { id, title, cards },
  { theme }
) => {
  return {
    id,
    theme: theme?.label,
    type: "InTheaters",
    animate: true,
    showButton: false,
    reveal: false,
    title,
    imageRatio: 409 / 302,
    noScrollbar: true,
    cards:
      cards &&
      cards.map(cardData => {
        const { data, url } = cardData?.article?.document || {};
        return {
          ...inTheatersCardAdapter({ card: { ...data, url } }),
        };
      }),
  };
};
