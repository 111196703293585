import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Popin from "../../Molecules/Popin/Popin";
import RichText from "../../Atoms/RichText";

import { scaleSpacing } from "../../../../styles/theme";
import { NotoLarge } from "../../Atoms/Typography";
import SwiperCarousel from "../../Molecules/SwiperCarousel/SwiperCarousel";

const Container = styled.section`
  position: relative;
`;

const PopinStyles = css`
  p {
    margin-bottom: ${scaleSpacing(4)};
    ${NotoLarge};
  }
`;

const CardCarousel = ({
  id,
  title,
  link,
  linkLabel,
  cards,
  type,
  animate,
  showButton,
  reveal,
  noScrollbar,
  imageRatio,
}) => {
  const [popinShown, setPopinShown] = useState(false);
  const [popinData, setPopinData] = useState(null);

  const show = detailsData => {
    setPopinShown(true);
    setPopinData(detailsData);
  };

  const hide = () => setPopinShown(false);

  if (!cards) return null;

  return (
    <Container>
      {!!title && !!cards && (
        <SwiperCarousel
          id={id}
          type={type}
          title={title}
          link={link}
          linkLabel={linkLabel}
          animate={animate}
          showButton={showButton}
          reveal={reveal}
          imageRatio={imageRatio}
          noScrollbar={noScrollbar}
          cards={cards}
          onClickCard={show}
        />
      )}

      <Popin shown={popinShown} hide={hide} css={PopinStyles}>
        <RichText data={popinData} />
      </Popin>
    </Container>
  );
};

CardCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  link: PropTypes.object,
  linkLabel: PropTypes.string,
  type: PropTypes.string,
  animate: PropTypes.bool,
  showButton: PropTypes.bool,
  reveal: PropTypes.bool,
  imageRatio: PropTypes.number,
  noScrollbar: PropTypes.bool,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      ctaLabel: PropTypes.string,
      ctaLink: PropTypes.object,
      detailsLabel: PropTypes.string,
      details: PropTypes.array,
      onClickDetaill: PropTypes.func,
      credits: PropTypes.string,
      image: PropTypes.object,
    })
  ).isRequired,
};

export default React.memo(CardCarousel);
