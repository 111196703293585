import { adapter as cardSmallAdapter } from "../../Molecules/CardSmall";

export default (
  { cards, id, number, type, authorContent, magazineContent },
  { t }
) => {
  const {
    authors_cta_label,
    authors_cta_link,
    authors_title,
    authors_carousel,
  } = authorContent || {};

  const {
    magazine_cta_label,
    magazine_carousel,
    magazine_title,
    magazine_cta_link,
  } = magazineContent || {};

  const free = t("curiosity_herowithcarousel_free");
  return {
    id,
    title:
      authors_title ||
      magazine_title ||
      t("curiosity_herowithcarousel_title", {
        count: number,
      }),
    authorsCtaLabel: authors_cta_label,
    authorsCtaLink: authors_cta_link,
    magazineCtaLabel: magazine_cta_label,
    magazineCtaLink: magazine_cta_link,
    hasLink: !!magazine_cta_link?.url || !!authors_cta_link?.url,
    free: free,
    type: type || "small",
    animate: true,
    cards: cards
      ?.map(card => ({ ...cardSmallAdapter({ card }, { t }) }))
      .filter(card => !!card.image),
    authors: authors_carousel?.map(card => ({
      ...cardSmallAdapter({ card }, { t }),
    })),
    magazines: magazine_carousel?.map(card => ({
      ...cardSmallAdapter({ card }, { t }),
    })),
  };
};
