import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import {
  scaleSpacing,
  mediaquery,
  rem,
  colors,
} from "../../../../styles/theme";
import {
  OswaldH3,
  OswaldH4,
  NotoEditoBody,
  NotoSmall,
} from "../../Atoms/Typography";

import Image from "../../Atoms/Image";
import Button from "../../Atoms/Button";
import RichText from "../../Atoms/RichText";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.white};
  margin-top: ${scaleSpacing(6)};
  margin-bottom: ${scaleSpacing(9)};

  ${mediaquery.md(css`
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${scaleSpacing(25)};
    margin-bottom: ${scaleSpacing(0)};

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
  `)};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  max-width: 100%;
  margin-bottom: ${scaleSpacing(8)};

  ${mediaquery.md(css`
    width: calc(var(--col) * 8 - var(--gutter-size));
    margin-right: calc(var(--col));
    margin: 0 calc(var(--col) / 2);
    margin-bottom: 0;
  `)};
`;

const Content = styled.div`
  width: 100%;
  ${mediaquery.md(css`
    margin: 0 calc(var(--col) / 2);
    width: calc(var(--col) * 12 - var(--gutter-size));
  `)};
`;

const TitleWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${scaleSpacing(8)};

  ${mediaquery.md(css`
    margin-bottom: ${scaleSpacing(10)};
  `)};
`;

const AvatarContainer = styled.div`
  margin-top: ${scaleSpacing(4)};
  width: ${scaleSpacing(19)};
  height: ${scaleSpacing(19)};
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  /* mask-image prevents an issue with safari : https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: -webkit-radial-gradient(white, black);

  img {
    display: block;
    width: 100%;
  }

  ${mediaquery.md(css`
    margin-right: ${scaleSpacing(6)};
    margin-top: 0;
    width: ${scaleSpacing(24)};
    height: ${scaleSpacing(24)};
  `)};
`;

const Description = styled.div`
  ${NotoEditoBody};
  max-width: 100%;
`;

const Title = styled.p`
  ${OswaldH4};

  ${mediaquery.md(css`
    ${OswaldH3};
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${scaleSpacing(8)};
`;

const CollabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${scaleSpacing(5.25)};
  ${NotoSmall};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${scaleSpacing(2)};
  width: ${scaleSpacing(14)};
  height: ${rem(40)};

  .gatsby-image-wrapper {
    display: flex;
    align-items: center;
    height: 50%;
  }

  img {
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Guest = ({
  picture,
  avatar,
  title,
  description,
  ctaLabel,
  ctaLink,
  sponsorsDescription,
  sponsors,
}) => {
  return (
    <Wrapper>
      {picture && (
        <ImageWrapper>
          <Image source={picture} sizes="40vw" mobileSizes="100vw" />
        </ImageWrapper>
      )}
      <Content>
        <TitleWrapper>
          {avatar && (
            <AvatarContainer>
              <Image source={avatar} sizes="56" isFixed />
            </AvatarContainer>
          )}
          {title && <Title>{title}</Title>}
        </TitleWrapper>
        {description && (
          <Description>
            <RichText data={description} />
          </Description>
        )}
        {sponsors?.length > 0 && (
          <CollabWrapper>
            {sponsorsDescription && (
              <span>
                <RichText data={sponsorsDescription} />
              </span>
            )}
            {sponsors.map(({ logo, uid }) => {
              return (
                <LogoContainer key={uid}>
                  <Image
                    source={logo}
                    sizes="40"
                    style={{ width: "100%", height: "100%", display: "flex" }}
                    imgStyle={{
                      height: "auto",
                      width: "100%",
                      margin: "auto",
                      bottom: 0,
                      right: 0,
                    }}
                    isFixed
                  />
                </LogoContainer>
              );
            })}
          </CollabWrapper>
        )}

        {ctaLabel && ctaLink && (
          <ButtonWrapper>
            <Button buttonTheme="primary" link={ctaLink}>
              {ctaLabel}
            </Button>
          </ButtonWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

Guest.propTypes = {
  picture: PropTypes.object.isRequired,
  avatar: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaLink: PropTypes.object.isRequired,
  sponsorsDescription: PropTypes.array,
  sponsors: PropTypes.array,
};

export default React.memo(Guest);
