export default ({ primary, items }) => {
  const { linkstitle } = primary;

  return {
    title: linkstitle,
    links:
      items &&
      items.map(({ link_label, link, icon }) => ({
        linkLabel: link_label,
        link,
        icon: icon,
      })),
  };
};
