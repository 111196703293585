import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { colors, mediaquery, scaleSpacing } from "../../../../styles/theme";
import {
  OswaldOverline1,
  OswaldOverline2,
  NotoLarge,
} from "../../Atoms/Typography";
import Guest from "../../Molecules/Guest";
import Link from "../../Atoms/Link";
import RichText from "../../Atoms/RichText";

const Container = styled.section`
  background: ${colors.darkgrey2};
  display: flex;
  flex-direction: column;
  padding: ${scaleSpacing(16)} var(--external-gutter) ${scaleSpacing(14)}
    var(--external-gutter);

  ${mediaquery.md(css`
    padding: ${scaleSpacing(25)} calc(var(--external-gutter) + var(--col))
      ${scaleSpacing(24)} calc(var(--external-gutter) + var(--col));
  `)};
`;

const TitleContainer = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.white};

  ${mediaquery.sm(css`
    align-items: center;
    flex-direction: row;
  `)};
`;

const Title = styled(RichText)`
  ${OswaldOverline2};
  letter-spacing: -0.001em;

  ${mediaquery.md(css`
    ${OswaldOverline1};
  `)};
`;

const MoreLink = styled(Link)`
  ${NotoLarge};
  line-height: ${scaleSpacing(4)};
  margin: ${scaleSpacing(4)} 0 0 0;

  ${({ theme }) => theme.title};
  ${mediaquery.sm(css`
    margin: 0 0 0 ${scaleSpacing(10)};
  `)};
`;

const Guests = ({ title, link, linkLabel, guests }) => {
  return (
    <Container>
      <TitleContainer>
        {title && <Title data={title} />}

        {linkLabel && link?.url && (
          <MoreLink link={link} linkTheme="dark">
            {linkLabel}
          </MoreLink>
        )}
      </TitleContainer>

      {guests &&
        guests.map((guestInfo, index) => <Guest key={index} {...guestInfo} />)}
    </Container>
  );
};

Guests.propTypes = {
  title: PropTypes.array,
  link: PropTypes.object,
  linkLabel: PropTypes.string,
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.object.isRequired,
      avatar: PropTypes.object,
      title: PropTypes.string.isRequired,
      description: PropTypes.array.isRequired,
      ctaLabel: PropTypes.string.isRequired,
      ctaLink: PropTypes.object.isRequired,
      filmLink: PropTypes.object.string,
      sponsorsDescription: PropTypes.array,
      sponsors: PropTypes.array,
    })
  ).isRequired,
};

export default React.memo(Guests);
