export default ({ primary, adsConfig }, { t }) => {
  const {
    teaser_description,
    teaser_image,
    teaser_title,
    teaser_cta_link,
    teaser_cta_label,
    enable_newsletter_link,
    enable_advertising,
    show_scroll_down,
    height_vh,
  } = primary.teaser?.document?.data || {};

  return {
    title: teaser_title?.raw,
    headline: !teaser_title ? t("curiosity_teaser_headline") : null,
    description: teaser_description?.raw || null,
    image: teaser_image,
    ctaLabel: teaser_cta_label,
    ctaLink: teaser_cta_link,
    showAd: enable_advertising,
    hasNewsletterLink: enable_newsletter_link,
    hasScrollDown: show_scroll_down,
    heightVh: height_vh,
    ads: adsConfig,
  };
};

export const teaserCategoryAdapter = ({
  title,
  label,
  subtitle,
  description,
  image,
  hero_image,
}) => {
  return {
    description: subtitle || description?.raw,
    title: title || label,
    image: image || hero_image,
    showAd: false,
  };
};
