export default ({ primary }) => {
  const {
    title,
    ctalink,
    ctalabel,
    cta_download_label,
    cta_download_link,
  } = primary;

  return {
    title: title?.raw,
    ctaLabel: ctalabel,
    ctaLink: ctalink,
    ctaDownloadLabel: cta_download_label,
    ctaDownloadLink: cta_download_link,
  };
};
