export default ({ items }) => {
  return {
    testimonials: items.map(
      ({
        testimonials: {
          document: {
            data: { picture, title, name, description, short, long, logo },
          },
        },
      }) => ({
        picture: picture.thumbnails?.avatar || picture,
        title,
        name,
        description,
        shortTestimonial: short?.raw,
        longTestimonial: long?.raw,
        logo: logo.thumbnails?.partner || logo,
      })
    ),
  };
};
