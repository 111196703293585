import { adapter } from "../../Molecules/Guest";
import { linkResolver } from "../../../config/linkResolver";

export default ({ primary, items }, { t }) => {
  const { guest_title } = primary || {};

  const moreLink = {
    type: "category",
    uid: "collections",
  };

  return {
    title: guest_title?.raw || t("curiosity_guest_title"),
    link: {
      url: linkResolver(moreLink),
    },
    linkLabel: t("curiosity_imagesCarousel_cta_more"),
    guests: items
      ?.map(guest => ({ ...adapter(guest) }))
      .filter(guest => !!guest?.title),
  };
};
