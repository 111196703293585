import { getPropValue } from "../../../utils/getPropValue.js";

export default ({ card }, { t }) => {
  // films
  const data = getPropValue(card, "film.document.data");
  const url = getPropValue(card, "film.document.url");
  const id = getPropValue(card, "film.document.id");
  const { duration, title, description, image, director, free, genre } =
    data || {};

  // authors
  const dataAuthor = getPropValue(card, "author.document.data");
  const { avatar, name, related_article } = dataAuthor || {};
  const { url: urlAuthor } = related_article || {};

  // magazines
  const dataMagazine = card;
  const { pdf_description, pdf_download_link, pdf_title, thumbnail_image } =
    dataMagazine || {};
  const img = image?.thumbnails?.sixteenByNine || avatar || thumbnail_image;
  return {
    id,
    link: {
      url: urlAuthor || url,
    },
    title,
    subtitle: director && `${t("curiosity_director_prefix")} ${director}`,
    description: description?.raw,
    pdfDescription: pdf_description,
    pdfDownloadLink: pdf_download_link,
    pdfTitle: pdf_title,
    image: img && (img.url || img.fluid?.src) && img,
    authorName: name,
    duration: duration,
    genre,
    free,
  };
};
