import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import RichText from "../../Atoms/RichText";
import {
  rem,
  scaleSpacing,
  colors,
  mediaquery,
} from "../../../../styles/theme";
import { OswaldH2, NotoEditoBody } from "../../Atoms/Typography/Typography";

import Image from "../../Atoms/Image";

const ContentWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${scaleSpacing(20)} var(--external-gutter);
  text-align: center;
  background: ${colors.darkgrey2};

  ${mediaquery.md(css`
    padding: ${scaleSpacing(25)} var(--external-gutter);
  `)};
`;

const Title = styled.div`
  color: ${colors.white};
  margin-bottom: ${scaleSpacing(4)};
  ${OswaldH2};
`;

const SponsorsNames = styled.div`
  color: ${colors.white};
  margin: 0;
  ${NotoEditoBody};
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${scaleSpacing(8)};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-width: 300px;
`;

const LogoContainer = styled.div`
  margin: ${scaleSpacing(1)} ${scaleSpacing(2)};
  width: ${rem(72)};
  height: ${rem(64)};
  flex-shrink: 0;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaquery.md(css`
    margin: 0 ${scaleSpacing(6)};
  `)};

  .gatsby-image-wrapper {
    display: flex;
    align-items: center;
    height: 50%;
  }

  img {
    margin: auto;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }
`;

const Sponsors = ({ title, description, sponsors }) => {
  return (
    <ContentWrapper>
      {title && <Title>{title}</Title>}
      {description && (
        <SponsorsNames>
          <RichText data={description} />
        </SponsorsNames>
      )}
      <LogoWrapper>
        {sponsors &&
          sponsors.map(({ logo }, index) => {
            const isPortraitLogo =
              logo.dimensions.height >= logo.dimensions.width;
            return (
              <LogoContainer key={index}>
                <Image
                  source={logo}
                  sizes="40px"
                  isFixed
                  style={{ width: "120%", height: "50%" }}
                  imgStyle={
                    isPortraitLogo
                      ? { height: "100%", width: "auto", position: "absolute" }
                      : { height: "auto", width: "100%", position: "absolute" }
                  }
                />
              </LogoContainer>
            );
          })}
      </LogoWrapper>
    </ContentWrapper>
  );
};

Sponsors.propTypes = {
  title: PropTypes.string,
  description: PropTypes.array.isRequired,
  sponsors: PropTypes.array.isRequired,
};

export default React.memo(Sponsors);
