import Hero, { adapter as heroAdapter } from "../components/Molecules/Hero";
import CardCarousel, {
  adapter as cardCarouselAdapter,
} from "../components/Organisms/CardCarousel";
import Testimonials, {
  adapter as testimonialsAdapter,
} from "../components/Organisms/Testimonials";
import BookingPush, {
  adapter as bookingAdapter,
} from "../components/Molecules/BookingPush";
import Newsletter, {
  adapter as newsletterAdapter,
} from "../components/Molecules/Newsletter";
import Links, { adapter as linksAdapter } from "../components/Molecules/Links";
import Guests, {
  adapter as guestsAdapter,
} from "../components/Organisms/Guests";
import Informations, {
  adapter as informationsAdapter,
} from "../components/Organisms/Informations";
import Distribution, {
  adapter as distributionAdapter,
} from "../components/Organisms/Distribution";
import Edito, { adapter as editoAdapter } from "../components/Organisms/Edito";
import Sponsors, {
  adapter as sponsorsAdapter,
} from "../components/Molecules/Sponsors";
import HeroWithCarousel, {
  adapter as heroWithCarouselAdapter,
} from "../components/Molecules/HeroWithCarousel";
import Teaser, {
  adapter as teaserAdapter,
} from "../components/Molecules/Teaser";
import { cardCarouselEpisodesSliceAdapter } from "../components/Organisms/CardCarousel/adapter";
import FigureList, {
  articlesSliceAdapter,
} from "../components/Molecules/FigureList";
import AdBanner, {
  adapter as adBannerAdapter,
} from "../components/Atoms/AdBanner";

export default {
  hero: {
    Component: Hero,
    mapper: heroAdapter,
  },
  cardscarousel: {
    Component: CardCarousel,
    mapper: cardCarouselAdapter,
  },
  slice_testimonials: {
    Component: Testimonials,
    mapper: testimonialsAdapter,
  },
  slice_booking: {
    Component: BookingPush,
    mapper: bookingAdapter,
  },
  newsletter: {
    Component: Newsletter,
    mapper: newsletterAdapter,
  },
  slice_links: {
    Component: Links,
    mapper: linksAdapter,
  },
  guests: {
    Component: Guests,
    mapper: guestsAdapter,
  },
  informations: {
    Component: Informations,
    mapper: informationsAdapter,
  },
  distribution: {
    Component: Distribution,
    mapper: distributionAdapter,
  },
  film_debate: {
    Component: Edito,
    mapper: editoAdapter,
  },
  sponsors: {
    Component: Sponsors,
    mapper: sponsorsAdapter,
  },
  slice_hero: {
    Component: Hero,
    mapper: heroAdapter,
  },

  slice_heroepisode: {
    Component: HeroWithCarousel,
    mapper: heroWithCarouselAdapter,
  },

  slice_teaser: {
    Component: Teaser,
    mapper: teaserAdapter,
    props: {
      heightVh: 100,
      hasScrollDown: true,
    },
  },

  slice_guests: {
    Component: Guests,
    mapper: guestsAdapter,
  },

  slice_episodes: {
    Component: CardCarousel,
    mapper: cardCarouselEpisodesSliceAdapter,
  },

  slice_sponsors: {
    Component: Sponsors,
    mapper: sponsorsAdapter,
  },

  slice_adbanner: {
    Component: AdBanner,
    mapper: adBannerAdapter,
  },

  slice_articles: {
    Component: FigureList,
    mapper: articlesSliceAdapter,
  },

  slice_newsletter: {
    Component: Newsletter,
    mapper: newsletterAdapter,
  },
};
