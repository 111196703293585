import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  colors,
  hexRgba,
  mediaquery,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import Button from "../../Atoms/Button";
import Image from "../../Atoms/Image";
import {
  OswaldH1,
  OswaldH2,
  OswaldOverline1,
  OswaldOverline2,
} from "../../Atoms/Typography";
import CardSmallCarousel, {
  adapter as cardSmallCarouselAdapter,
} from "../../Organisms/CardSmallCarousel";
import RichText from "../../Atoms/RichText";
import { splitButtonLabel } from "../../../../styles/buttons";

const Container = styled.section`
  overflow: hidden;
`;

const TopContainer = styled.div`
  position: relative;
  height: calc(var(--initialvh, 1vh) * 100);

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${hexRgba(colors.black, 0.4)};
    z-index: ${zIndexes.middle};
  }
`;

const BottomContainer = styled.div`
  position: relative;
  z-index: ${zIndexes.top};
  margin-top: calc(var(--initialvh, 1vh) * -15);
  background-color: ${colors.darkgrey2};
  background: linear-gradient(
    0deg,
    rgba(25, 25, 25, 1) 80%,
    rgba(25, 25, 25, 0) 100%
  );

  ${mediaquery.l(css`
    background: linear-gradient(
      0deg,
      rgba(25, 25, 25, 1) 60%,
      rgba(25, 25, 25, 0) 100%
    );
  `)};

  ${mediaquery.l(css`
    margin-top: calc(var(--initialvh, 1vh) * -20);
  `)};
`;

const containerWidth = css`
  width: calc(100% - 2 * var(--external-gutter));

  ${mediaquery.md(css`
    width: calc(var(--col) * 12);
  `)};
`;

const ContentContainer = styled.div`
  z-index: ${zIndexes.top};
  position: relative;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  ${mediaquery.md(css`
    margin-bottom: 0;
    padding-top: 0;
  `)};
`;

const Title = styled.div`
  color: ${colors.white};
  text-align: center;
  width: 100%;
  margin-bottom: ${scaleSpacing(6)};
  ${OswaldH2}
  ${containerWidth}

  ${mediaquery.md(css`
    ${OswaldH1}
  `)};
`;

const Headline = styled.div`
  color: ${colors.white};
  text-align: center;
  width: 100%;
  margin-bottom: ${scaleSpacing(4)};
  ${OswaldOverline2}
  ${containerWidth}

  ${mediaquery.md(css`
    ${OswaldOverline1}
  `)};
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.bottom};
`;

const SplitButton = styled.span`
  ${splitButtonLabel}
`;

const HeroWithCarousel = ({
  title,
  free,
  label,
  backgroundImage,
  ctaLink,
  ctaLabel,
  cards,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TopContainer>
        <ImageContainer>
          {backgroundImage && (
            <Image
              source={backgroundImage}
              sizes="100vw"
              mobileSizes="100vw"
              objectFit="cover"
            />
          )}
        </ImageContainer>

        <ContentContainer>
          {!!label && <Headline>{label}</Headline>}
          {!!title && (
            <Title>
              <RichText data={title} />
            </Title>
          )}

          <Button buttonTheme="primary" link={ctaLink}>
            {ctaLabel ? ctaLabel : t("curiosity_herowithcarousel_discover")}
            {!ctaLabel && <SplitButton>{free}</SplitButton>}
          </Button>
        </ContentContainer>
      </TopContainer>
      <BottomContainer>
        {!!cards && (
          <CardSmallCarousel
            {...cardSmallCarouselAdapter(
              { cards, id: "hero-cards-small" },
              { t }
            )}
          />
        )}
      </BottomContainer>
    </Container>
  );
};

HeroWithCarousel.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  free: PropTypes.string,
  backgroundImage: PropTypes.object.isRequired,
  ctaLink: PropTypes.object,
  ctaLabel: PropTypes.string,
  cards: PropTypes.array,
};

export default React.memo(HeroWithCarousel);
