export const linkResolver = doc => {
  if (!doc) return null;

  const website = process.env.GATSBY_WEBSITE;

  if (website === "curiosity") {
    switch (doc && doc.type) {
      case "category":
        return `/${doc.uid}`;
      case "category_curiosity":
        return `/category/${doc.uid}`;
      case "episode":
        return `/episode/${doc.uid}`;
      case "film":
        return `/film/${doc.uid}`;
      case "guest":
        return `/invite/${doc.uid}`;
      case "collection":
        return `/collection/${doc.uid}`;
      case "home_curiosity":
        return "/";
      case "search":
        return "/search";
      case "article":
        return `/article/${doc.uid}`;
      default:
        return `/${doc.type}/${doc.uid}`;
    }
  } else if (website === "trois_couleurs") {
    switch (doc && doc.type) {
      case "article":
        return `/article/${doc.uid}`;
      case "category_troiscouleurs":
        return `/category/${doc.uid}`;
      case "search":
        return "/search";
      case "home_troiscouleurs":
        return "/";
      default:
        return `/${doc.type}/${doc.uid}`;
    }
  } else if (website === "institut" && doc && doc.type === "home_institut") {
    return "/";
  } else {
    return `/${doc.type}/${doc.uid}`;
  }
};

export const previewLinkResolver = () => linkResolver;
