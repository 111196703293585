import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { withPreview } from "gatsby-source-prismic";
import Layout from "../components/layout";
import SliceZone from "../components/SliceZone";
import { adConfigAdapter } from "../utils/configAdapter";
import { withTrans } from "../i18n/withTrans";
import { adapter as seoAdapter } from "../components/seo";

const HomepageInstitut = ({ data, location, pageContext }) => {
  const {
    uid,
    type,
    data: {
      smartadserver_page_name,
      smartadserver_page_id,
      smartadserver_disable_masthead,
    },
  } = data.prismicHomeInstitut || {};

  const prismicPageContent = { uid, ...data.prismicHomeInstitut.data };

  const prismicSiteConfiguration = data.allPrismicSiteConfiguration?.edges?.find(
    ({ node }) => node.data.site === pageContext.website
  ).node.data;

  const adsConfig = React.useMemo(
    () =>
      prismicSiteConfiguration &&
      adConfigAdapter(
        {
          ...prismicSiteConfiguration,
          smartadserver_disable_masthead,
          smartadserver_page_name,
          smartadserver_page_id,
        },
        type
      ),
    [
      prismicSiteConfiguration,
      smartadserver_disable_masthead,
      smartadserver_page_id,
      smartadserver_page_name,
      type,
    ]
  );

  return (
    <Layout seoData={seoAdapter(data.prismicHomeInstitut)} location={location}>
      <SliceZone
        sliceZone={prismicPageContent}
        template="institut"
        ads={adsConfig}
      />
    </Layout>
  );
};

export const query = graphql`
  query homeInstitutQuery {
    ...i18n
    prismicHomeInstitut {
      uid
      url
      type
      data {
        body {
          ...Hero
          ...Testimonials
          ...CardsCarousel
          ...Booking
          ...Links
          ...NewsletterInstitut
          ...SponsorsInstitut
        }
      }
    }
  }
`;

HomepageInstitut.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

HomepageInstitut.defaultProps = {
  data: {},
};

export default withPreview(withTrans(HomepageInstitut));
