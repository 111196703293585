import { getPropValue } from "../../../utils/getPropValue";
export default ({ primary }) => {
  // getPropValue handles the case where some nested property is undefined - In this case, video
  const { title, description, image, cta_label, video_enable } = primary;

  // video property under primary
  const video = getPropValue(primary, "video.document.data");

  return {
    title: title?.raw,
    description,
    backgroundImage: image,
    ctaLabel: cta_label,
    videoEnable: video_enable,
    video: {
      title: video?.title,
      id: video?.vimeoid,
    },
  };
};
