import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Button from "../../Atoms/Button";
import Svg from "../../Atoms/Svg";
import {
  scaleSpacing,
  colors,
  mediaquery,
  rem,
} from "../../../../styles/theme";
import { Oswald } from "../../Atoms/Typography";

const Wrapper = styled.section`
  width: 100%;
  padding: ${scaleSpacing(12)} 0 ${scaleSpacing(12)} ${scaleSpacing(6)};
  background-color: ${colors.lightgrey};

  ${mediaquery.md(css`
    width: 100%;
    padding: ${scaleSpacing(12)} 0 ${scaleSpacing(12)} ${scaleSpacing(42)};
  `)};
`;

const Title = styled.h2`
  ${Oswald};
  font-size: ${rem(24)};
  line-height: ${rem(30)};
  letter-spacing: -0.001em;
  color: ${colors.black};
  margin-bottom: ${scaleSpacing(6)};

  ${mediaquery.md(css`
    margin-bottom: ${scaleSpacing(8)};
  `)};
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ${mediaquery.md(css`
    flex-direction: row;
  `)};

  li {
    display: flex;
    margin: 0 ${scaleSpacing(3)} ${scaleSpacing(4)} 0;

    &:first-of-type {
      margin-left: 0;
    }

    ${mediaquery.md(css`
      display: block;
      margin: 0 ${scaleSpacing(3)};
    `)};
  }
`;

const LinkButton = ({ label, link, icon }) => (
  <Button
    buttonTheme="primaryRed"
    link={link}
    css={css`
      padding: ${scaleSpacing(3.5)} ${scaleSpacing(7)};
      ${mediaquery.sm(css`
        justify-content: center;
        padding: ${scaleSpacing(4)} ${scaleSpacing(12)};
      `)};
    `}
  >
    <span
      css={css`
        margin-right: ${icon ? scaleSpacing(4) : 0};
        ${mediaquery.sm(css`
          display: inline-block;
        `)};
      `}
    >
      {label}
    </span>
    {icon && <Svg name={icon} size={22} height={22} fill={colors.white} />}
  </Button>
);

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  icon: PropTypes.string,
};

const Links = ({ title, links }) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {!!links && (
        <List>
          {links.map(({ linkLabel, link, icon }) => (
            <li key={linkLabel.replace(/\W/g, "_")}>
              <LinkButton label={linkLabel} link={link} icon={icon} />
            </li>
          ))}
        </List>
      )}
    </Wrapper>
  );
};
Links.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      linkLabel: PropTypes.string.isRequired,
      link: PropTypes.object.isRequired,
      icon: PropTypes.string,
    })
  ),
};
export default React.memo(Links);
