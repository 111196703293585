import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Button from "../../Atoms/Button";
import Svg from "../../Atoms/Svg";
import RichText from "../../Atoms/RichText";
import {
  scaleSpacing,
  colors,
  mediaquery,
  rem,
} from "../../../../styles/theme";
import { OswaldTitle } from "../../Atoms/Typography";
import PushBG from "../../../assets/booking-push-bg.svg";
import { grid } from "../../../../styles/grid";

const Wrapper = styled.section`
  padding-top: ${scaleSpacing(16)};
  padding-bottom: ${scaleSpacing(16)};

  ${mediaquery.sm(css`
    ${grid};
    padding-top: ${scaleSpacing(25)};
    padding-bottom: ${scaleSpacing(25)};
  `)};
`;
const Container = styled.div`
  padding: 0 var(--external-gutter);
  ${mediaquery.md(css`
    padding: 0;
    grid-column: 3 / -3;
  `)};
`;
const Push = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.black};
  padding: ${scaleSpacing(12)} ${scaleSpacing(8)};
  margin-top: 0;

  background-image: url("${PushBG}");
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${mediaquery.md(css`
    flex-direction: row;
    align-items: center;
    padding: ${scaleSpacing(15)};
  `)};
`;
const Title = styled.div`
  ${OswaldTitle};
  color: ${colors.white};
  margin-right: auto;
  margin-left: 0;
  font-size: ${rem(24)};
  line-height: ${rem(30)};
`;

export const buttonStyles = css`
  margin-top: ${scaleSpacing(4)};
  ${mediaquery.md(css`
    margin-top: 0;
    margin-left: ${scaleSpacing(8)};
  `)};
`;

const BookingPush = ({
  title,
  ctaLabel,
  ctaLink,
  ctaDownloadLabel,
  ctaDownloadLink,
}) => (
  <Wrapper>
    <Container>
      <Push>
        {title && (
          <Title>
            <RichText data={title} />
          </Title>
        )}
        {ctaLink && ctaLabel && (
          <Button buttonTheme="primaryRed" link={ctaLink} css={buttonStyles}>
            {ctaLabel}
          </Button>
        )}
        {ctaDownloadLabel && ctaDownloadLink && (
          <Button
            buttonTheme="primary"
            link={ctaDownloadLink}
            css={buttonStyles}
          >
            <span
              css={css`
                margin-right: ${scaleSpacing(4)};
                ${mediaquery.sm(css`
                  display: inline-block;
                `)};
              `}
            >
              {ctaDownloadLabel}
            </span>{" "}
            <Svg name="download" size={22} height={22} />
          </Button>
        )}
      </Push>
    </Container>
  </Wrapper>
);
BookingPush.propTypes = {
  title: PropTypes.array.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaLink: PropTypes.object.isRequired,
  ctaDownloadLabel: PropTypes.string.isRequired,
  ctaDownloadLink: PropTypes.object.isRequired,
};
export default React.memo(BookingPush);
