export default ({ card }, { t }) => {
  const {
    category,
    subtitle,
    title,
    free,
    description,
    credits,
    ctalabel,
    linkLabel,
    url,
    detail_popin,
    image,
    type,
  } = card || {};

  return {
    topic: category,
    title: title?.raw || title,
    free,
    subtitle: subtitle?.raw,
    description: type == "Portrait" ? null : description?.raw,
    ctaLabel: ctalabel || linkLabel,
    ctaButtonTheme: "primary",
    ctaLink: { url },
    detailsLabel: t("curiosity_card_ctaLabel_details"),
    details: detail_popin?.raw,
    credits,
    image: image?.thumbnails?.nineBySixteen || image,
  };
};

export const episodesCardAdapter = ({ card }, { t }) => {
  const { title, free, url, excerpt, description, image } = card;

  return {
    title: title || description?.raw,
    free,
    description: excerpt,
    ctaLabel: t("curiosity_card_ctaLabel"),
    ctaButtonTheme: "primary",
    ctaLink: { url },
    image: image?.thumbnails?.nineBySixteen || image,
  };
};

export const podcastsCardAdapter = ({ card }) => {
  const { title, free, linkLabel, image, format, url, description } = card;

  return {
    title,
    free,
    ctaLabel: linkLabel,
    ctaLink: { url },
    category: format,
    image: image?.thumbnails?.nineBySixteen || image,
    description: description?.raw,
  };
};

export const inTheatersCardAdapter = ({ card }) => {
  const { title, image, format, url } = card;

  return {
    title,
    ctaLink: { url },
    category: format,
    image: image?.thumbnails?.twoByThree || image,
  };
};
