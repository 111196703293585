import { sponsorAdapter } from "../../Molecules/Sponsors/adapter";

export default ({ guest }) => {
  const { url, data } = guest?.document || {};
  const {
    guest_title,
    guest_description,
    guest_featured_description,
    guest_cta_label,
    featured_image,
    picture,
    avatar,
    films,
    sponsors,
    sponsors_title,
    sponsors_description,
  } = data || {};

  const filmUrl = films && films[0]?.film?.document?.url;
  // CtaLink url depends on how many films related
  const ctaLinkUrl = !filmUrl || films.length > 1 ? url : filmUrl;

  return {
    title: guest_title,
    description: guest_featured_description?.raw?.length
      ? guest_featured_description?.raw
      : guest_description?.raw,
    featureDescription: guest_featured_description?.raw,
    ctaLabel: guest_cta_label,
    picture: featured_image?.url ? featured_image : picture,
    avatar: avatar.url && (avatar?.thumbnails?.avatar || avatar),
    ctaLink: {
      url: ctaLinkUrl,
    },
    sponsorsTitle: sponsors_title,
    sponsorsDescription: sponsors_description?.raw,
    sponsors: sponsors
      ?.map(sponsor => ({ ...sponsorAdapter(sponsor) }))
      .filter(sponsor => !!sponsor.logo),
  };
};
